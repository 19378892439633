import React, {useEffect, useState} from 'react';
import {FormError} from '@wix/form-viewer/widget';
import {ContactForm} from '../../../../Form/ContactForm/ContactForm';
import {classes} from './BillingDetails.st.css';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {FormLoader} from '../../../../Form/FormLoader/FormLoader';
import {BillingAddressDropdown} from '../BillingAddressDropdown/BillingAddressDropdown';
import {useBillingData} from '../WithBillingData/WithBillingData';
import {AddressForm} from '../../../../Form/AddressForm/AddressForm';
import {usePaymentsApi} from '../../../../WithPaymentsApi/WithPaymentsApi';
import {updateMandatoryFields} from '../../../../../../domain/utils/cashier.utils';
import {getCountryFromAddressFormValues} from '../../../../Form/AddressForm/addressForm.utils';
import {VatForm} from '../../../../Form/VatIdForm/VatForm';
import {FIELDS_GAP} from '../../../../constants';
import {BillingAddressTitle} from '../BillingAddressTitle/BillingAddressTitle';

export enum BillingDetailsDataHook {
  root = 'BillingDetails.root',
  contactForm = 'BillingDetails.contactForm',
  addressForm = 'BillingDetails.addressForm',
  vatForm = 'BillingDetails.vatForm',
}

export const BillingDetails = () => {
  const {
    formsStore: {areFormsLoaded},
    memberStore: {isMember},
  } = useControllerProps();

  const {
    setContactFormValues,
    contactFormValues,
    contactFormViewer,
    vatFormValues,
    setVatFormValues,
    vatFormViewer,
    addressFormViewer,
    addressFormValues,
    setAddressFormValues,
    cashierMandatoryFields,
    setCashierMandatoryFields,
    updateContactCountry,
  } = useBillingData();

  const {paymentsApi} = usePaymentsApi();

  const [contactFormErrors, setContactFormErrors] = useState<FormError[]>([]);
  const [addressFormErrors, setAddressFormErrors] = useState<FormError[]>([]);
  const [vatFormErrors, setVatFormErrors] = useState<FormError[]>([]);
  const [country, setCountry] = useState<string | undefined>(undefined);

  useEffect(
    () => {
      const newCountry = getCountryFromAddressFormValues(addressFormValues);
      if (newCountry !== country) {
        setCountry(newCountry);
        void updateContactCountry(newCountry);
        void updateMandatoryFields(paymentsApi, setCashierMandatoryFields, country);
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */ [paymentsApi, addressFormValues]
  );

  return (
    <div data-hook={BillingDetailsDataHook.root} className={classes.root}>
      <BillingAddressTitle />
      {isMember && <BillingAddressDropdown />}
      {!areFormsLoaded && <FormLoader />}
      {areFormsLoaded && (
        <div
          className={classes.forms}
          style={{
            gap: `${FIELDS_GAP}px`,
          }}>
          <div data-hook={BillingDetailsDataHook.contactForm}>
            <ContactForm
              formRef={contactFormViewer}
              formValues={contactFormValues}
              formErrors={contactFormErrors}
              setFormValues={setContactFormValues}
              setFormErrors={setContactFormErrors}
              overridePhone={cashierMandatoryFields.phone}
            />
          </div>
          {country === 'BR' && (
            <div data-hook={BillingDetailsDataHook.vatForm}>
              <VatForm
                formRef={vatFormViewer}
                formValues={vatFormValues}
                formErrors={vatFormErrors}
                setFormValues={setVatFormValues}
                setFormErrors={setVatFormErrors}
              />
            </div>
          )}
          <div data-hook={BillingDetailsDataHook.addressForm}>
            <AddressForm
              formRef={addressFormViewer}
              formValues={addressFormValues}
              formErrors={addressFormErrors}
              setFormValues={setAddressFormValues}
              setFormErrors={setAddressFormErrors}
              overrides={cashierMandatoryFields}
            />
          </div>
        </div>
      )}
    </div>
  );
};
