import {FormValues} from '@wix/form-viewer';
import {VatIDValue} from '@wix/form-fields';
import {ContactModel} from '../../../../domain/models/Contact.model';
import {CommonVatId, CommonVatType} from '@wix/ambassador-ecom-v1-checkout/types';

export const getVatFormInitialState = (contact?: ContactModel) => ({
  vat_id: {
    id: contact?.vatId?.id ?? '',
    type: contact?.vatId?.type ?? CommonVatType.UNSPECIFIED,
  },
});

export const getVatFromVatFormValues = (vatFormValues: FormValues): CommonVatId | undefined => {
  const vatId = vatFormValues.vat_id as VatIDValue;
  if (vatId.id) {
    return {
      id: vatId.id,
      type: getCommonVatType(vatId.type),
    };
  }
  return undefined;
};

const getCommonVatType = (type: string): CommonVatType => {
  switch (type) {
    /* istanbul ignore next */ case 'CPF':
      /* istanbul ignore next */ return CommonVatType.CPF;
    case 'CNPJ':
      return CommonVatType.CNPJ;
  }
  /* istanbul ignore next */ return CommonVatType.UNSPECIFIED;
};
