import {useTranslation} from '@wix/yoshi-flow-editor';
import {useEffect} from 'react';

export function useI18nWithoutEscapeValue() {
  const {t, i18n} = useTranslation();

  useEffect(() => {
    i18n.options.interpolation = i18n.options.interpolation ?? /* istanbul ignore next */ {};
    i18n.options.interpolation.escapeValue = false;
  }, [i18n]);

  return {t, i18n};
}
