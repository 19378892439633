import React, {ComponentType, createContext, useContext, useRef, useState} from 'react';
import {FormHandle, FormValues} from '@wix/form-viewer';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {FormViewerHandle} from '@wix/form-viewer/widget';
import {FullAddressContactDetails, ApiAddress, CommonVatId} from '@wix/ambassador-ecom-v1-checkout/types';
import {
  getContactDetailsFromContactFormValues,
  getContactFormCurrentStateWithUpdatedCountry,
  getContactFormInitialState,
} from '../../../../Form/ContactForm/contactForm.utils';
import {CashierMandatoryFieldsOverrides} from '../../../../../../types/app.types';
import {
  getAddressFormInitialState,
  getAddressFromAddressFormValues,
} from '../../../../Form/AddressForm/addressForm.utils';
import {getVatFormInitialState, getVatFromVatFormValues} from '../../../../Form/VatIdForm/VatForm.utils';

export type BillingDataContextType = {
  setContactFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
  contactFormValues: FormValues;
  contactFormViewer: React.RefObject<FormHandle>;
  setAddressFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
  addressFormValues: FormValues;
  addressFormViewer: React.RefObject<FormHandle>;
  isFormValid: () => Promise<boolean>;
  initForm: () => void;
  updateContactCountry: (country: string) => void;
  getContactDetailsFormValues: () => FullAddressContactDetails;
  getAddressFormValues: () => ApiAddress;
  cashierMandatoryFields: CashierMandatoryFieldsOverrides;
  setCashierMandatoryFields: React.Dispatch<React.SetStateAction<CashierMandatoryFieldsOverrides>>;
  vatFormViewer: React.RefObject<FormHandle>;
  vatFormValues: FormValues;
  setVatFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
  getVatFormValues: () => CommonVatId | undefined;
};

export const BillingDataContext = createContext({} as BillingDataContextType);

export function withBillingData<T extends object>(Component: ComponentType<T>) {
  return function Wrapper(props: T) {
    const {
      checkoutStore: {checkout},
      checkoutSettingsStore: {checkoutSettings},
    } = useControllerProps();

    const [contactFormValues, setContactFormValues] = useState<FormValues>(
      getContactFormInitialState({
        checkoutSettings,
        contact: checkout.billingInfo?.contact,
        country: checkout.billingInfo?.address?.country,
      })
    );
    const [vatFormValues, setVatFormValues] = useState<FormValues>(
      getVatFormInitialState(checkout.billingInfo?.contact)
    );
    const [addressFormValues, setAddressFormValues] = useState<FormValues>(
      getAddressFormInitialState(checkoutSettings, checkout.billingInfo?.address)
    );

    const [cashierMandatoryFields, setCashierMandatoryFields] = useState<CashierMandatoryFieldsOverrides>({});

    const contactFormViewer = useRef<FormViewerHandle>(null);
    const vatFormViewer = useRef<FormViewerHandle>(null);
    const addressFormViewer = useRef<FormViewerHandle>(null);

    const formRefs = [contactFormViewer, vatFormViewer, addressFormViewer];

    const isFormValid = async () => {
      const areFormsValidArr = await Promise.all(
        formRefs.filter((ref) => !!ref.current).map((ref) => ref.current!.validate())
      );

      return !areFormsValidArr.includes(false);
    };

    const initForm = () => {
      setContactFormValues(
        getContactFormInitialState({
          checkoutSettings,
          contact: checkout.billingInfo?.contact,
          country: checkout.billingInfo?.address?.country,
        })
      );
      setVatFormValues(getVatFormInitialState(checkout.billingInfo?.contact));
      setAddressFormValues(getAddressFormInitialState(checkoutSettings, checkout.billingInfo?.address));
    };

    const updateContactCountry = (country: string) => {
      setContactFormValues(
        getContactFormCurrentStateWithUpdatedCountry({
          contactFormValues,
          country,
        })
      );
    };

    const getContactDetailsFormValues = () => {
      return getContactDetailsFromContactFormValues(contactFormValues, checkoutSettings);
    };

    const getAddressFormValues = () => {
      return getAddressFromAddressFormValues(checkoutSettings, addressFormValues);
    };

    const getVatFormValues = () => {
      return getVatFromVatFormValues(vatFormValues);
    };

    return (
      <BillingDataContext.Provider
        value={{
          contactFormValues,
          setContactFormValues,
          contactFormViewer,
          isFormValid,
          initForm,
          updateContactCountry,
          getContactDetailsFormValues,
          cashierMandatoryFields,
          setCashierMandatoryFields,
          vatFormViewer,
          vatFormValues,
          setVatFormValues,
          getVatFormValues,
          addressFormViewer,
          addressFormValues,
          setAddressFormValues,
          getAddressFormValues,
        }}>
        <Component {...props} />
      </BillingDataContext.Provider>
    );
  };
}

export function useBillingData() {
  const {
    contactFormValues,
    setContactFormValues,
    contactFormViewer,
    isFormValid,
    initForm,
    updateContactCountry,
    getContactDetailsFormValues,
    cashierMandatoryFields,
    setCashierMandatoryFields,
    vatFormViewer,
    vatFormValues,
    setVatFormValues,
    getVatFormValues,
    addressFormViewer,
    addressFormValues,
    setAddressFormValues,
    getAddressFormValues,
  } = useContext(BillingDataContext);

  return {
    contactFormValues,
    setContactFormValues,
    contactFormViewer,
    isFormValid,
    initForm,
    updateContactCountry,
    getContactDetailsFormValues,
    cashierMandatoryFields,
    setCashierMandatoryFields,
    vatFormViewer,
    vatFormValues,
    setVatFormValues,
    getVatFormValues,
    addressFormViewer,
    addressFormValues,
    setAddressFormValues,
    getAddressFormValues,
  };
}
